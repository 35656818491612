<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			class="row no-gutters"
			:style="containerStyle">
			<div
				:class="{
					'd-flex align-items-center': !['xs', 'sm'].includes(windowWidth),
					'order-12': ['xs', 'sm'].includes(windowWidth),
					'justify-content-end': !['xs', 'sm'].includes(windowWidth),
				}"
				class="col-12 col-md-6 col-lg-6">
				<div
					:class="{
						'px-4 py-3': ['xs', 'sm', 'md'].includes(windowWidth),
						'pr-5': !['xs', 'sm'].includes(windowWidth),
					}"
					:style="['xs', 'sm'].includes(windowWidth) ? '' : 'max-width: 100%; width: 380px'">
					<div
						:class="['xs', 'sm'].includes(windowWidth) ? 'justify-content-center' : ''"
						class="row">
						<div :class="['xs', 'sm'].includes(windowWidth) ? 'col' : 'col-auto'">
							<div class="row">
								<div :class="['xs', 'sm'].includes(windowWidth) ? 'col' : 'col-auto'">
									<div class="row">
										<div class="col">
											<span
												:style="`${makeHighlightStyle('color')} ${['xs', 'sm'].includes(windowWidth) ? 'font-size: 2.2rem' : 'white-space: nowrap'}`"
												class="section-header">
												{{ title }}
											</span>
										</div>
									</div>
									<div class="row mb-4">
										<div class="col">
											<span
												class="text-white"
												v-html="content" />
										</div>
									</div>
									<div
										v-if="!!Object.keys(cta).length"
										:class="['xs', 'sm'].includes(windowWidth) ? 'justify-content-center' : ''"
										class="row">
										<div class="col">
											<div id="email-button-tooltip">
												<button
													:class="{
														'w-100': ['xs', 'sm'].includes(windowWidth),
														'pointer': !loading && email,
													}"
													:disabled="!email"
													class="btn btn-lg text-white rounded-1 py-2 cta-button"
													style="border: 1px solid #fff"
													@click.prevent="email ? openMailer() : null">
													<div
														:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'mx-2'"
														class="font-weight-bolder h6 my-2">
														<template v-if="!loading">
															{{ cta.text }}
														</template>
														<template v-else>
															<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('loading') }}
														</template>
													</div>
												</button>
											</div>
											<b-tooltip
												:disabled="loading || !!email"
												target="email-button-tooltip"
												triggers="hover">
												{{ translate('contact_not_available') }}
											</b-tooltip>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				:class="['xs', 'sm'].includes(windowWidth) ? '' : 'pl-5'"
				class="col-12 col-md-6 col-lg-6 m-0">
				<div
					:class="['xs', 'sm'].includes(windowWidth) ? '' : 'h-100'"
					class="d-block w-100"
					:style="backgroundStyles" />
			</div>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { LandingPages } from '@/translations';
import ReplicatedSite from '@/util/ReplicatedSite';
import Color from '../../mixins/Color';

export default {
	name: 'ContactSection',
	messages: [LandingPages],
	mixins: [Color, WindowSizes],
	props: {
		email: {
			type: String,
			default: '',
		},
		loading: {
			type: Boolean,
			default: false,
		},
		image: {
			type: String,
			default: () => [],
		},
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		cta: {
			type: Object,
			default: () => ({}),
		},
		containerStyle: {
			required: true,
			type: String,
			default: '',
		},
	},
	data() {
		return {
			replicated: new ReplicatedSite(),
		};
	},
	computed: {
		backgroundStyles() {
			const styles = [
				['xs', 'sm'].includes(this.windowWidth) ? 'height: 30vw;' : '',
				`background-image: url('${this.image}');`,
				'background-size: cover;',
				'background-position: center;',
				'background-repeat: none;',
			];

			return styles.join(' ');
		},
	},
	methods: {
		openMailer() {
			this.$emit('mail');
		},
	},
};
</script>
<style scoped>
button.cta-button:not(:disabled):hover {
	opacity: 0.85 !important;
}
</style>
