var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row no-gutters",style:(_vm.containerStyle)},[_c('div',{staticClass:"col-12 col-md-6 col-lg-6",class:{
				'd-flex align-items-center': !['xs', 'sm'].includes(_vm.windowWidth),
				'order-12': ['xs', 'sm'].includes(_vm.windowWidth),
				'justify-content-end': !['xs', 'sm'].includes(_vm.windowWidth),
			}},[_c('div',{class:{
					'px-4 py-3': ['xs', 'sm', 'md'].includes(_vm.windowWidth),
					'pr-5': !['xs', 'sm'].includes(_vm.windowWidth),
				},style:(['xs', 'sm'].includes(_vm.windowWidth) ? '' : 'max-width: 100%; width: 380px')},[_c('div',{staticClass:"row",class:['xs', 'sm'].includes(_vm.windowWidth) ? 'justify-content-center' : ''},[_c('div',{class:['xs', 'sm'].includes(_vm.windowWidth) ? 'col' : 'col-auto'},[_c('div',{staticClass:"row"},[_c('div',{class:['xs', 'sm'].includes(_vm.windowWidth) ? 'col' : 'col-auto'},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"section-header",style:(((_vm.makeHighlightStyle('color')) + " " + (['xs', 'sm'].includes(_vm.windowWidth) ? 'font-size: 2.2rem' : 'white-space: nowrap')))},[_vm._v(" "+_vm._s(_vm.title)+" ")])])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"text-white",domProps:{"innerHTML":_vm._s(_vm.content)}})])]),(!!Object.keys(_vm.cta).length)?_c('div',{staticClass:"row",class:['xs', 'sm'].includes(_vm.windowWidth) ? 'justify-content-center' : ''},[_c('div',{staticClass:"col"},[_c('div',{attrs:{"id":"email-button-tooltip"}},[_c('button',{staticClass:"btn btn-lg text-white rounded-1 py-2 cta-button",class:{
													'w-100': ['xs', 'sm'].includes(_vm.windowWidth),
													'pointer': !_vm.loading && _vm.email,
												},staticStyle:{"border":"1px solid #fff"},attrs:{"disabled":!_vm.email},on:{"click":function($event){$event.preventDefault();_vm.email ? _vm.openMailer() : null}}},[_c('div',{staticClass:"font-weight-bolder h6 my-2",class:['xs', 'sm'].includes(_vm.windowWidth) ? 'w-100' : 'mx-2'},[(!_vm.loading)?[_vm._v(" "+_vm._s(_vm.cta.text)+" ")]:[_c('i',{staticClass:"fa fa-fw fa-spinner fa-pulse"}),_vm._v(" "+_vm._s(_vm.translate('loading'))+" ")]],2)])]),_c('b-tooltip',{attrs:{"disabled":_vm.loading || !!_vm.email,"target":"email-button-tooltip","triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.translate('contact_not_available'))+" ")])],1)]):_vm._e()])])])])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-6 m-0",class:['xs', 'sm'].includes(_vm.windowWidth) ? '' : 'pl-5'},[_c('div',{staticClass:"d-block w-100",class:['xs', 'sm'].includes(_vm.windowWidth) ? '' : 'h-100',style:(_vm.backgroundStyles)})])])])}
var staticRenderFns = []

export { render, staticRenderFns }