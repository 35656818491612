var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"h-100",class:_vm.windowWidth === 'xs' ? 'mt-5' : '',style:(_vm.backgroundStyle)},[_c('b-row',{class:( _obj = {}, _obj[_vm.backgroundLayer] = ['xs', 'sm', 'md'].includes(_vm.windowWidth), _obj['d-flex align-items-center justify-content-center'] =  ['xs', 'sm', 'md'].includes(_vm.windowWidth), _obj ),style:(_vm.backgroundHeight)},[_c('b-col',{staticClass:"d-flex align-items-center px-5 logo-container",class:{
					'justify-content-center': ['xs', 'sm', 'md'].includes(_vm.windowWidth),
				},attrs:{"cols":['xs', 'sm', 'md'].includes(_vm.windowWidth) ? '10' : '12',"lg":_vm.logo ? '6' : '5'}},[(!!_vm.logo)?_c('img',{staticClass:"embed-responsive",attrs:{"src":_vm.logo,"alt":"logo"}}):_vm._e()]),_c('b-col',{staticClass:"d-flex",class:_vm.contentClasses,attrs:{"cols":"12","lg":_vm.logo ? '6' : '7'}},[_c('b-row',{staticClass:"px-4",class:{
						'd-flex align-items-center justify-content-center': ['xs', 'sm', 'md'].includes(_vm.windowWidth),
					}},[(!!_vm.image)?_c('b-col',{attrs:{"cols":"10 content-logo-container"}},[_c('img',{staticClass:"embed-responsive",attrs:{"src":_vm.image,"alt":"image"}})]):_vm._e(),_vm._l((_vm.content),function(item,index){return _c('b-col',{key:index,attrs:{"cols":"12"}},[_c('div',{class:['xs', 'sm', 'md'].includes(_vm.windowWidth) ? _vm.itemClass(item).small : _vm.itemClass(item).big,style:(((item.highlight ? _vm.makeHighlightStyle('color') : '') + " " + (['xs', 'sm', 'md'].includes(_vm.windowWidth) ? item.style.small : item.style.big))),domProps:{"innerHTML":_vm._s(item.text)}})])}),_c('b-col',{attrs:{"cols":"12"}},[_vm._t("flavors")],2),_c('b-col',{class:['xs', 'sm', 'md'].includes(_vm.windowWidth) ? 'mb-3' : '',attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-nowrap",class:_vm.alignButtons,staticStyle:{"gap":"1rem"}},_vm._l((_vm.ctas),function(cta,index){
					var _obj;
return _c('button',{key:index,staticClass:"learn-more-btn btn btn-lg rounded-0 border-2 mb-1",class:( _obj = {}, _obj[cta.class] = true, _obj['px-5'] =  !['xs', 'sm', 'md'].includes(_vm.windowWidth), _obj ),style:(((cta.type === 'filled' ? 'background-color' : 'color') + ": " + (cta.color) + "; border-color: " + (cta.color))),on:{"click":function($event){return _vm.handleClick(cta)}}},[_c('span',{staticClass:"learn-more font-weight-bolder letter-spacing m-0",staticStyle:{"font-size":"1rem"}},[_vm._v(" "+_vm._s(_vm.translate(cta.text))+" ")])])}),0)])],2)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }