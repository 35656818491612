<!-- eslint-disable vue/no-v-html -->
<template>
	<div :style="`--highlight-color: ${highlightColor}`">
		<b-row>
			<b-col
				v-if="['xs', 'sm', 'md'].includes(windowWidth)"
				class="mb-5"
				cols="12">
				<div
					class="d-flex"
					style="gap: 4px; z-index: 2">
					<div
						class="d-flex justify-content-center align-items-center text-white pointer"
						:style="`flex: 1; height: 50px; background-color: ${tabNames[2].color}; border-top-left-radius: 10px`"
						@click="$emit('setSlide', 0)">
						<b>{{ tabNames[2].title }}</b>
					</div>
					<div
						class="d-flex justify-content-center align-items-center text-white pointer"
						:style="`flex: 1; height: 50px; background-color: ${tabNames[1].color}`"
						@click="$emit('setSlide', 1)">
						<b>{{ tabNames[1].title }}</b>
					</div>
					<div
						class="d-flex justify-content-center align-items-center text-white pointer"
						:style="`flex: 1; height: 50px; background-color: ${tabNames[0].color}; border-top-right-radius: 10px`"
						@click="$emit('setSlide', 2)">
						<b>{{ tabNames[0].title }}</b>
					</div>
				</div>
				<div :style="`background: linear-gradient(to right, ${[...tabNames].reverse()[currentTab].gradient.from}, ${[...tabNames].reverse()[currentTab].gradient.to}); height: 10px`" />
			</b-col>
			<b-col
				v-if="!['xs', 'sm', 'md'].includes(windowWidth)"
				cols="12"
				lg="4">
				<div style="position: relative; height: 700px">
					<div
						class="d-flex"
						style="transform: rotate(-90deg); gap: 4px; position: absolute; top: 342px; left: -50px; z-index: 2">
						<div
							class="d-flex justify-content-center align-items-center text-white pointer"
							:style="`width: 109px; height: 50px; background-color: ${tabNames[0].color}; border-top-left-radius: 10px`"
							@click="$emit('setSlide', 2)">
							<b>{{ tabNames[0].title }}</b>
						</div>
						<div
							class="d-flex justify-content-center align-items-center text-white pointer"
							:style="`width: 109px; height: 50px; background-color: ${tabNames[1].color}`"
							@click="$emit('setSlide', 1)">
							<b>{{ tabNames[1].title }}</b>
						</div>
						<div
							class="d-flex justify-content-center align-items-center text-white pointer"
							:style="`width: 108px; height: 50px; background-color: ${tabNames[2].color}; border-top-right-radius: 10px`"
							@click="$emit('setSlide', 0)">
							<b>{{ tabNames[2].title }}</b>
						</div>
					</div>
					<img
						:src="banner"
						style="position: absolute; top: 200px; left: 145px; z-index: -2"
						alt="banner">
					<img
						:src="image"
						style="position: absolute; top: 0; right: -205px; z-index: -1"
						alt="mist">
				</div>
			</b-col>
			<b-col
				cols="12"
				lg="8"
				class="d-flex flex-column justify-content-center">
				<h1
					:class="{
						'display-5': ['xs', 'sm'].includes(windowWidth),
						'text-center': ['xs', 'sm', 'md'].includes(windowWidth),
						'display-4': !['xs', 'sm'].includes(windowWidth),
					}"
					v-html="title" />
				<div
					:class="{
						'text-center': ['xs', 'sm', 'md'].includes(windowWidth),
					}"
					v-html="description" />
				<div class="my-5">
					<div
						v-for="(iconRow, rowIndex) in iconRows"
						:key="`iconRow-${rowIndex}`"
						:class="rowIndex === iconRows.length - 1 ? '' : 'mb-5'"
						class="row justify-content-start">
						<div
							v-for="(icon, iconIndex) in iconRow"
							:key="`iconCol-${iconIndex}`"
							class="d-flex flex-column align-items-center justify-content-start"
							:class="{
								'col-auto': !['xs', 'sm', 'md'].includes(windowWidth),
								'col': ['xs', 'sm', 'md'].includes(windowWidth),
							}">
							<img
								class="img-fluid"
								style="height: 50px; !important"
								:src="icon.file"
								width="50rem"
								alt="benefits icon">
							<span
								class="mt-3 text-center"
								style="max-width: 85px;">
								<span
									class="d-inline"
									v-html="icon.text" />
								<template>
									<sup
										class="font-weight-bold"
										style="color: var(--highlight-color)">‡</sup>
								</template>
							</span>
						</div>
					</div>
				</div>
				<div
					:class="{
						'text-center': ['xs', 'sm', 'md'].includes(windowWidth),
					}"
					v-html="bottomText" />
				<div
					class="d-flex mt-4"
					:class="{
						'flex-column': ['xs', 'sm'].includes(windowWidth),
						'justify-content-center': ['md'].includes(windowWidth),
					}"
					style="gap: 10px">
					<b-button
						v-if="ctas[0].videoUrl[language]"
						v-b-modal="'product-overview-video'"
						class="overview-supplements-button custom-btn font-weight-bolder"
						variant="primary"
						@click="$emit('videoUrl', ctas[0].videoUrl[language])">
						{{ ctas[0].text }}
					</b-button>
					<b-button
						v-b-modal="'product-overview-supplement-facts'"
						class="overview-learn-more-button custom-btn font-weight-bolder"
						variant="outline-primary"
						@click="$emit('supplementFacts', supplementFacts)">
						{{ ctas[1].text }}
					</b-button>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import Color from '@/views/LandingPages2/mixins/Color';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'ProductOverview',
	mixins: [Color, WindowSizes],
	props: {
		tabNames: {
			type: Array,
			required: true,
			validate: (value) => value.length === 3,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		bottomText: {
			type: String,
			required: true,
		},
		banner: {
			type: String,
			required: true,
		},
		image: {
			type: String,
			required: true,
		},
		icons: {
			type: Array,
			required: true,
		},
		supplementFacts: {
			type: Object,
			required: true,
		},
		ctas: {
			type: Array,
			required: true,
			validate: (value) => value.length === 2,
		},
		currentTab: {
			type: Number,
			required: true,
		},
	},
	computed: {
		iconRows() {
			const { windowWidth } = this;
			const colsPerWindowWidth = {
				xs: 2,
				sm: 2,
				md: 5,
				lg: 6,
				xl: 6,
			};
			const rows = [];

			this.icons.forEach((icon) => {
				if (rows.length === 0 || rows[rows.length - 1].length >= colsPerWindowWidth[windowWidth]) {
					rows.push([]);
				}
				rows[rows.length - 1].push(icon);
			});
			return rows;
		},
	},
};
</script>

<style scoped>
:root {
	--highlight-color: #00a1e0;
}

.custom-btn {
	padding-top: 10px;
	padding-bottom: 10px;
}
.overview-supplements-button {
	background-color: var(--highlight-color);
	border-color: var(--highlight-color);
}
.overview-supplements-button:hover,
.overview-supplements-button:active {
	background-color: var(--highlight-color) !important;
}
.overview-supplements-button:hover {
	color: white !important;
	opacity: 0.95 !important;
}
.overview-supplements-button:focus {
	box-shadow: 0 0 0 0 !important;
	border-color: var(--highlight-color) !important;
}

.overview-learn-more-button {
	border-color: var(--highlight-color);
	color: var(--highlight-color);
}
.overview-learn-more-button:hover,
.overview-learn-more-button:active {
	background-color: var(--highlight-color) !important;
}
.overview-learn-more-button:hover {
	color: white !important;
}
.overview-learn-more-button:focus {
	box-shadow: 0 0 0 0 !important;
	border-color: var(--highlight-color) !important;
}
</style>
